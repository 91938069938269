import { HeadFC } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { BackgroundHome, FollowUs, Logo, Seo } from '../../components';

export default function Home() {
  return (
    <main>
      <BackgroundHome>
        <ContentStyle>
          <Logo />
          <TitleStyle>Coming back soon!</TitleStyle>
          <MessageStyle>
            We're currently working on this feature and we will relaunch soon!
          </MessageStyle>
          <FollowUs />
        </ContentStyle>
      </BackgroundHome>
    </main>
  );
}

export const Head: HeadFC = () => <Seo />;

const ContentStyle = styled.div`
  --margin-horizontal: 28px;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  margin-left: var(--margin-horizontal);
  margin-right: var(--margin-horizontal);
  margin-top: 60px;
  text-align: center;

  /* Media Queries 
  ======================================================================================== */
  ${({ theme }) => theme.screens.mediaQueries['md-lg']} {
    margin-bottom: 91px;
    margin-top: 102px;
  }

  ${({ theme }) => theme.screens.mediaQueries.lg} {
    margin-bottom: 91px;
    margin-top: 92px;
  }
`;

const TitleStyle = styled.h1`
  font-size: 36px;
  font-weight: var(--fw-semi-bold);
  margin-bottom: 28px;
  margin-top: 42px;
  //TODO: font-family: 'All Round Gothic';

  /* Media Queries 
  ======================================================================================== */
  ${({ theme }) => theme.screens.mediaQueries['md-lg']} {
    font-size: 52px;
    margin-bottom: 40px;
    margin-top: 60px;
  }

  ${({ theme }) => theme.screens.mediaQueries.lg} {
    font-size: 60px;
    margin-bottom: 40px;
    margin-top: 60px;
  }
`;

const MessageStyle = styled.p`
  --padding-horizontal: 5px;

  font-size: 16px;
  font-weight: var(--fw-medium);
  line-height: 1.5;
  margin-bottom: 40px;
  max-width: 250px;
  padding-left: var(--padding-horizontal);
  padding-right: var(--padding-horizontal);
  /* Media Queries 
  ======================================================================================== */
  ${({ theme }) => theme.screens.mediaQueries.md} {
    font-size: 18px;
    line-height: 1.75;
    margin-bottom: 60px;
    max-width: 463px;
  }

  ${({ theme }) => theme.screens.mediaQueries.lg} {
    margin-bottom: 60px;
    max-width: 433px;
  }
`;
